import React from "react";
import Header from "../components/sections/Header";
import HeadData from "../data/HeadData";
import ContentContainer from "../components/container/ContentContainer";
import ContactExpert from "../components/controls/ContactExpert";
import TitleDiv from "../components/controls/TitleDiv";
import Layout from "../components/layout/Layout";

const PrivacyPage = () => {
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData title="Privacy" />

        <Header />

        <TitleDiv parentText="Privacy" />

        <ContentContainer
          titleText="Privacy Policy"
          titleClassName="text-3xl font-sans font-normal mb-10"
          className="bg-white text-gray-800 p-5 lg:p-20 w-full flex flex-col items-center justify-center max-w-6xl"
        >
          <div className="w-full flex flex-col justify-center items-center md:items-start text-center md:text-left">
            <p>
              Protecting your private information is our priority. This Statement
              of Privacy applies to https://nwpsocal.com/ and National Wood
              Products and governs data collection and usage. For the purposes of
              this Privacy Policy, unless otherwise noted, all references to
              National Wood Products include https://nwpsocal.com/. The National
              Wood Products website is a National Wood Products is your wholesale
              hardwood lumber, plywood & melamine supplier in Southern California
              site. By using the National Wood Products website, you consent to
              the data practices described in this statement.
            </p>
            <br />
            <p>Collection of your Personal Information</p>
            <p>
              National Wood Products may collect anonymous demographic
              information, which is not unique to you, such as your:
            </p>
            <br />
            <p>– Age</p>
            <p>– Gender</p>
            <p>– Household income</p>
            <br />
            <p>
              Please keep in mind that if you directly disclose personally
              identifiable information or personally sensitive data through
              National Wood Products’s public message boards, this information may
              be collected and used by others.
            </p>
            <br />
            <p>
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services available on the Site. These may
              include: (a) registering for an account on our Site; (b) entering a
              sweepstakes or contest sponsored by us or one of our partners; (c)
              signing up for special offers from selected third parties; (d)
              sending us an email message; (e) submitting your credit card or
              other payment information when ordering and purchasing products and
              services on our Site. To wit, we will use your information for, but
              not limited to, communicating with you in relation to services
              and/or products you have requested from us. We also may gather
              additional personal or non-personal information in the future.
            </p>
            <p>Sharing Information with Third Parties</p>
            <p>
              National Wood Products does not sell, rent or lease its customer
              lists to third parties.
            </p>
            <br />
            <p>
              National Wood Products may share data with trusted partners to help
              perform statistical analysis, send you email or postal mail, provide
              customer support, or arrange for deliveries. All such third parties
              are prohibited from using your personal information except to
              provide these services to National Wood Products, and they are
              required to maintain the confidentiality of your information.
            </p>
            <br />
            <p>
              National Wood Products may disclose your personal information,
              without notice, if required to do so by law or in the good faith
              belief that such action is necessary to: (a) conform to the edicts
              of the law or comply with legal process served on National Wood
              Products or the site; (b) protect and defend the rights or property
              of National Wood Products; and/or (c) act under exigent
              circumstances to protect the personal safety of users of National
              Wood Products, or the public.
            </p>
            <br />
            <p>Tracking User Behavior</p>
            <p>
              National Wood Products may keep track of the websites and pages our
              users visit within National Wood Products, in order to determine
              what National Wood Products services are the most popular. This data
              is used to deliver customized content and advertising within
              National Wood Products to customers whose behavior indicates that
              they are interested in a particular subject area.
            </p>
            <br />
            <p>Automatically Collected Information</p>
            <p>
              Information about your computer hardware and software may be
              automatically collected by National Wood Products. This information
              can include: your IP address, browser type, domain names, access
              times and referring website addresses. This information is used for
              the operation of the service, to maintain quality of the service,
              and to provide general statistics regarding use of the National Wood
              Products website.
            </p>
            <br />
            <p>Use of Cookies</p>
            <p>
              The National Wood Products website may use “cookies” to help you
              personalize your online experience. A cookie is a text file that is
              placed on your hard disk by a web page server. Cookies cannot be
              used to run programs or deliver viruses to your computer. Cookies
              are uniquely assigned to you, and can only be read by a web server
              in the domain that issued the cookie to you.
            </p>
            <br />
            <p>
              One of the primary purposes of cookies is to provide a convenience
              feature to save you time. The purpose of a cookie is to tell the Web
              server that you have returned to a specific page. For example, if
              you personalize National Wood Products pages, or register with
              National Wood Products site or services, a cookie helps National
              Wood Products to recall your specific information on subsequent
              visits. This simplifies the process of recording your personal
              information, such as billing addresses, shipping addresses, and so
              on. When you return to the same National Wood Products website, the
              information you previously provided can be retrieved, so you can
              easily use the National Wood Products features that you customized.
            </p>
            <p>
              You have the ability to accept or decline cookies. Most Web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. If you choose to
              decline cookies, you may not be able to fully experience the
              interactive features of the National Wood Products services or
              websites you visit.
            </p>
            <br />
            <p>Links</p>
            <p>
              This website contains links to other sites. Please be aware that we
              are not responsible for the content or privacy practices of such
              other sites. We encourage our users to be aware when they leave our
              site and to read the privacy statements of any other site that
              collects personally identifiable information.
            </p>
            <br />
            <p>Security of your Personal Information</p>
            <p>
              National Wood Products secures your personal information from
              unauthorized access, use, or disclosure. National Wood Products uses
              the following methods for this purpose:
            </p>
            <br />
            <p>– SSL Protocol</p>
            <br />
            <p>
              When personal information (such as a credit card number) is
              transmitted to other websites, it is protected through the use of
              encryption, such as the Secure Sockets Layer (SSL) protocol.
            </p>
            <br />
            <p>
              We strive to take appropriate security measures to protect against
              unauthorized access to or alteration of your personal information.
              Unfortunately, no data transmission over the Internet or any
              wireless network can be guaranteed to be 100% secure. As a result,
              while we strive to protect your personal information, you
              acknowledge that: (a) there are security and privacy limitations
              inherent to the Internet which are beyond our control; and (b)
              security, integrity, and privacy of any and all information and data
              exchanged between you and us through this Site cannot be guaranteed.
            </p>
            <br />
            <p>Children Under Thirteen</p>
            <p>
              National Wood Products does not knowingly collect personally
              identifiable information from children under the age of thirteen. If
              you are under the age of thirteen, you must ask your parent or
              guardian for permission to use this website.
            </p>
            <br />
            <p>E-mail Communications</p>
            <p>
              From time to time, National Wood Products may contact you via email
              for the purpose of providing announcements, promotional offers,
              alerts, confirmations, surveys, and/or other general communication.
            </p>
            <br />
            <p>
              If you would like to stop receiving marketing or promotional
              communications via email from National Wood Products, you may opt
              out of such communications by christina@nwpsocal.com.
            </p>
            <br />
            <p>External Data Storage Sites</p>
            <p>
              We may store your data on servers provided by third party hosting
              vendors with whom we have contracted.
            </p>
            <br />
            <p>Changes to this Statement</p>
            <p>
              National Wood Products reserves the right to change this Privacy
              Policy from time to time. We will notify you about significant
              changes in the way we treat personal information by sending a notice
              to the primary email address specified in your account, by placing a
              prominent notice on our site, and/or by updating any privacy
              information on this page. Your continued use of the Site and/or
              Services available through this Site after such modifications will
              constitute your: (a) acknowledgment of the modified Privacy Policy;
              and (b) agreement to abide and be bound by that Policy.
            </p>
            <br />
            <p>Contact Information</p>
            <p>
              National Wood Products welcomes your questions or comments regarding
              this Statement of Privacy. If you believe that National Wood
              Products has not adhered to this Statement, please contact National
              Wood Products at:
            </p>
            <br />
            <p>National Wood Products</p>
            <p>14450 Central Ave.</p>
            <p>Chino, California 91710</p>
            <br />
            <p>Email Address:</p>
            <p>cdennis@nationalwood.com</p>
            <br />
            <p>Telephone number:</p>
            <p>(909) 287-7906</p>
            <br />
            <p>Effective as of March 04, 2020</p>
          </div>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default PrivacyPage;
